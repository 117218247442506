import React from 'react'
import PageTitle from '../../components/pagetitle/pagetitle'
import Services from '../../components/service/service'

export default function ServicesPage() {
    React.useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <React.Fragment>
            <PageTitle title={"Services"} />
            <Services />
        </React.Fragment>
    )
}
