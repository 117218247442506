import React from 'react'
import PageTitle from '../../components/pagetitle/pagetitle'
import PublishersSection from '../../components/publishers/form/form'

export default function Publishers() {
    React.useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <React.Fragment>
            <PageTitle title={"Publishers"} />
            <PublishersSection />
        </React.Fragment>
    )
}
