import React from 'react'
import ContactSection from '../../components/contactus/form/form'
import PageTitle from '../../components/pagetitle/pagetitle'

export default function ContactUs() {
    React.useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <React.Fragment>
            <PageTitle title={"Contact Us"} />
            <ContactSection />
        </React.Fragment>
    )
}
