import React from "react";
import "./style.css";
import {
  Availability,
  Client_Centric_Approach,
  Cost_Effective,
  Quality_Assurance,
  Seamless_Integration,
} from "../../../utils/imagepath";
import ImageLoader from "../../loader/imageloader";
const WhyUsContnet = ({ title, desc, alt, image, right, animation }) => {
  return (
    <div
      className={`flex py-8 px-4 flex-wrap items-center ${
        right ? "flex-row-reverse" : ""
      }`}
    >
      <div
        data-aos={!animation && "flip-right"}
        data-aos-easing="ease-out-cubic"
        data-aos-duration="1000"
        className={` w-full md:w-1/2 ${
          right ? "md:pl-5" : "md:pr-5"
        } text-left`}
      >
        <h2 className="text-3xl font-bold mb-4">{title}</h2>
        <p className="text-gray-700 text-lg	">{desc}</p>
      </div>
      <div
        data-aos={!animation && "flip-left"}
        data-aos-easing="ease-out-cubic"
        data-aos-duration="1000"
        className="w-full md:w-1/2 mb-8 mt-8 md:mt-0 md:mb-0"
      >
        <ImageLoader
          src={image}
          alt={alt}
          className="object-cover rounded-lg h-full w-full"
        />
      </div>
    </div>
  );
};
const WhyUsPage = () => {
  return (
    <div className="why-us-page">
      <WhyUsContnet
        title={"Our Commitment to Quality:"}
        animation={false}
        desc={`At AgsLeads, quality is not just a goal; it’s our standard. We are
          dedicated to delivering high-quality call center services that exceed
          industry benchmarks. Our commitment to quality assurance is evident in
          every interaction, ensuring that your brand is represented with
          professionalism, accuracy, and a commitment to excellence. We
          continuously monitor and optimize our processes to uphold the highest
          standards, making AgsLeads your reliable partner in maintaining
          superior customer experiences.`}
        image={Quality_Assurance}
        alt={"Quality Assurance"}
      />
      <WhyUsContnet
        title={"24/7 Availability:"}
        right={true}
        desc={`In the fast-paced world of business, opportunities and challenges
          don’t adhere to a 9-to-5 schedule. That’s why AgsLeads operates 24/7,
          ensuring that your business is always accessible to customers,
          regardless of time zones or geographical locations. Whether it’s
          addressing urgent customer queries or capturing leads in different
          markets, our round-the-clock availability ensures that you never miss
          a beat.`}
        image={Availability}
        alt={"24/7 Availability"}
      />
      <WhyUsContnet
        title={"Cost-Effective Solutions:"}
        desc={`In the fast-paced world of business, opportunities and challenges
          don’t adhere to a 9-to-5 schedule. That’s why AgsLeads operates 24/7,
          ensuring that your business is always accessible to customers,
          regardless of time zones or geographical locations. Whether it’s
          addressing urgent customer queries or capturing leads in different
          markets, our round-the-clock availability ensures that you never miss
          a beat.`}
        image={Cost_Effective}
        alt={"Cost-Effective Solutions"}
      />
      <WhyUsContnet
        title={"Client-Centric Approach:"}
        right={true}
        desc={`At the heart of AgsLeads is a client-centric philosophy. We view our
          clients not just as business partners but as collaborators in success.
          Our team collaborates closely with you to understand your unique
          challenges and goals, ensuring that our call center services are
          aligned with your business objectives. Your success is our success,
          and we go the extra mile to build lasting relationships based on
          trust, transparency, and mutual growth.`}
        image={Client_Centric_Approach}
        alt={"Client-Centric Approach"}
      />
      <WhyUsContnet
        title={"Scalability with Seamless Integration:"}
        desc={`As your business grows, so do your call center needs. AgsLeads offers
          scalable solutions that seamlessly integrate with your evolving
          requirements. Whether you’re a startup looking for initial support or
          an established enterprise seeking to expand, our flexible services can
          be tailored to match your growth trajectory, ensuring that your call
          center solution is always aligned with your business goals.`}
        image={Seamless_Integration}
        alt={"Scalability with Seamless Integration"}
      />
    </div>
  );
};

export default WhyUsPage;
