import React from 'react'
import BlogSection from '../../components/home/blogs/blogs'
import PageTitle from '../../components/pagetitle/pagetitle'

export default function BlogScreen() {
    React.useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <React.Fragment>
            <PageTitle title={"Blogs"} />
            <BlogSection showDesc={true} />
        </React.Fragment>
    )
}
