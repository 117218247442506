import React from "react";
import "./style.css";
import ImageLoader from "../loader/imageloader";
import { About_us } from "../../utils/imagepath";
const AboutUsPage = ({ title }) => {
  return (
    <div className="why-us-page">
      {title && (
        <div className="block-text center">
          {/* <h6 className="sub-heading">
            <span></span>
          </h6> */}
          <h3 className="text-5xl text-black mb-4 font-bold">{title}</h3>
        </div>
      )}
      <div className={`flex py-8 px-4 flex-wrap items-center`}>
        <div
          // data-aos={"flip-right"}
          // data-aos-easing="ease-out-cubic"
          // data-aos-duration="1000"
          className={` w-full md:w-1/2  text-left`}
        >
          <p className="text-gray-700 text-lg	md:pr-5">
            AGS Leads is a one-stop solution for your innovative advertising and
            marketing services. Our specialization is pay-per-call advertising
            and marketing solutions tailored to your unique needs. We are expert
            in health and legal verticals, solar and few more. We also provide
            comprehensive customer support solutions to the business. We pride
            ourselves on delivering high-quality inbounds and transfers,
            ensuring that every lead is opt-it and fully TCPA compliant. Our
            advertising services are designed to drive results and maximize your
            ROI, whether you are looking to generate leads, increase
            conversions, or enhance brand visibility. With a commitment to
            compliance and a focus on delivering exceptional marketing
            solutions, we're here to help your business thrive. Partner with AGS
            Leads today to experience the difference of working with a trusted
            leader in the industry. Contact us to learn more about how we can
            elevate your advertising and marketing efforts.
          </p>
        </div>
        <div
          // data-aos={"flip-left"}
          // data-aos-easing="ease-out-cubic"
          // data-aos-duration="1000"
          className="w-full md:w-1/2 mb-8 mt-8 md:mt-0 md:mb-0"
        >
          <ImageLoader
            src={About_us}
            alt={"About us"}
            className="object-cover rounded-lg h-full w-full"
          />
        </div>
      </div>
    </div>
  );
};

export default AboutUsPage;
