import React from 'react'
import PageTitle from '../../components/pagetitle/pagetitle'
import WhyUsPage from '../../components/whyus/whyuscontent/whyuspage'

export default function WhyUs() {
    React.useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <React.Fragment>
            <PageTitle title={"Why us"} />
            <WhyUsPage />
        </React.Fragment>
    )
}
