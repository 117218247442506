import React from 'react'
import BlogSection from '../../components/home/blogs/blogs'
import Roadmap from '../../components/home/services/roadmap'
import SpecializationSection from '../../components/home/specailization/specailization'
import Testimonials from '../../components/reviews/testimonials'
import CounterList from '../../components/home/counter'
import Services from '../../components/service/service'
import PageTitle from '../../components/pagetitle/pagetitle'
import AboutUsPage from '../../components/aboutus/aboutus'

export default function Home() {
    React.useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <React.Fragment>
            {/* <SliderComponent /> */}
            <Roadmap />
            <Services title={'Services'} para={'High-quality, targeted calls, well-qualified leads, and exceptional customer support to boost marketing, streamline sales, and enhance customer satisfaction.'} />
            <AboutUsPage title={'About Us'} />
            <Testimonials />
            <SpecializationSection />
            {/* <CounterList /> */}
            <BlogSection />
        </React.Fragment>
    )
}
