import React from 'react'
import PageTitle from '../../components/pagetitle/pagetitle'
import Testimonials from '../../components/reviews/testimonials/index'

export default function Reviews() {
    React.useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <React.Fragment>
            <PageTitle title={"Reviews"} />
            <Testimonials />
        </React.Fragment>
    )
}
