import React, { useEffect, useState } from 'react';

const ImageLoader = ({ src, alt, className, style }) => {
  const [loading, setLoading] = useState(true);

  const handleLoad = () => {
    setLoading(false);
  };
  const handleImageLoad = () => {
    setLoading(false);
  };

  const handleImageError = () => {
    console.error('Error loading image');
  };

  const loadImage = () => {
    const img = new Image();
    img.src = src;
    img.onload = handleImageLoad;
    img.onerror = handleImageError;
  };

  // Load the image when the component mounts
  useEffect(() => {
    loadImage();
  }, [src]);

  return loading ? (<></>) : (
    <img
      src={src}
      alt={alt}
      className={`${className}`}
      // onLoad={handleLoad}
      style={style}
      loading="lazy"
    />
    // <div className="relative">
    //   {loading && (
    //     <div className="absolute inset-0 flex items-center justify-center bg-gray-200">
    //       <div className="loader">Loading...</div>
    //     </div>
    //   )}
    // </div>
  );
};

export default ImageLoader;
