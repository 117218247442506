import React from "react";
// import SwiperCore, { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
// import "swiper/swiper-bundle.min.css";
import "./style.css";
import { TEXTIMONIALS_1, TEXTIMONIALS_2, TEXTIMONIALS_3, TEXTIMONIALS_4 } from "../../../utils/imagepath";

// SwiperCore.use([Pagination]);

const Testimonial = ({ image, name, position, text }) => {
    return (
        <div className="box-testimonial center" data-aos="zoom-in">
            <div className="image">
                <img src={image} alt={name} />
            </div>
            <div className="info mt-5 text-center flex justify-center flex-col items-center">
                <h5 className="name text-black font-semibold text-lg">{name}</h5>
                <p className="text-black text-sm">{position}</p>
                <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAFfSURBVHgB7ZbhcYMwDIVFrwOwQRkhG5QRskGyAd0ANmg2YARGYAS6Ad3A6QSqfSe3DweMcNr84t3pAKFPEjY2EO3a9Sgxc2nt3dooNlhrrRX/xtqAhuNqIuwliYWixlptLRd/bu0sTzCbAIo6tpphzSwrQ8SSvFhorIAEJfjPqay70YnzRBFBkQ58vZKtJa5Fp+vG0Ipk6Caxfoi3sk/id3NyXYOzLHMxny7eJniBof1QslepRc+0Xb7BL/AVlCKYp1IRO7rARNYgiy9Nr4CdBriuNazM8YT1NwafgJeXRRm+1QHbbWX9Whv5V66BQxDTzi2de1gMwF3KLCQvFtgqwpoYexMIvkbAliISNix80bA3RVixpUbYo4qFjlnODzBMa9viz9PKeQnskVbgUQJra2/QRE0rCthGzfL0mzpC95Wi6BJ7IgUcqmfdn0eeyvoE/rfFHV9pg+5hd+36U30DN5iksmFmPq8AAAAASUVORK5CYII=" alt=""></img>
            </div>
            <p className="text font-normal text-base text-black">{text}</p>
        </div>
    );
};

const Testimonials = () => {
    const testimonialsData = [
        {
            image: TEXTIMONIALS_1,
            name: "Milan Doe",
            position: "Designation",
            text:
                "“AGS Leads' Pay Per Call service has boosted our conversion rates with high-quality, genuinely interested prospects. Professional and responsive team. Highly recommended.“",
        },
        {
            image: TEXTIMONIALS_2,
            name: "Lina Mary",
            position: "Designation",
            text:
                "“AGS Leads provides top-notch lead generation with accurate data and high-potential leads. Slightly faster turnaround time would be ideal, but overall, very satisfied.“",
        },
        {
            image: TEXTIMONIALS_3,
            name: "Shila Karen",
            position: "Designation",
            text:
                "“Exceptional customer support from AGS Leads. Highly trained, efficient team that has significantly improved our customer satisfaction and retention. Seamless integration and excellent service.“",
        },
        {
            image: TEXTIMONIALS_4,
            name: "Esther Howard",
            position: "Designation",
            text:
                "AGS Leads delivers well-qualified leads that streamline our sales process. Impressive lead quality and responsive customer service. Initial setup took some time, but results are worth it.“",
        },
    ];

    return (
        <div className="testimonials__main">
            <div className="block-text center">
                <h6 className="sub-heading">
                    <span>Testimonials</span>
                </h6>
                <h3 className="heading mb-6	 text-5xl text-black font-bold">
                    What People Say <br /> about us
                </h3>
            </div>
            <Swiper
                spaceBetween={30}
                slidesPerView={1}
                breakpoints={{
                    // when window width is >= 640px
                    640: {
                        slidesPerView: 1,
                        spaceBetween: 20,
                    },
                    // when window width is >= 768px
                    768: {
                        slidesPerView: 2,
                        spaceBetween: 30,
                    },
                    // when window width is >= 1024px
                    1024: {
                        slidesPerView: 3,
                        spaceBetween: 40,
                    },
                }}

                pagination={{ clickable: true }}
            >
                {testimonialsData.map((testimonial, index) => (
                    <SwiperSlide key={index}>
                        <Testimonial {...testimonial} />
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
};

export default Testimonials;
