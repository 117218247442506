import React, { useState } from 'react';
import './style.css';
import axios from 'axios';
import { toast } from 'react-toastify';
import { BACKEND_URL } from '../../../utils/constant';
import { Select } from 'antd';
const AdvertisersSection = () => {
    const [advertiserObject, setAdvertiserObject] = useState({
        name: '',
        company_name: '',
        email: '',
        campaigns: [],
    })
    const [disabled, setDisabled] = useState(false)
    const options = [];
    const handleChange = (value) => {
        console.log(`selected ${value}`);
        advertiserObjectFn('campaigns', value)
    };
    const advertiserObjectFn = (key, value) => {
        setAdvertiserObject({ ...advertiserObject, [key]: value })
    }
    const onSubmitFn = async (event) => {
        event.preventDefault();
        setDisabled(true)
        try {
            let object = {
                name: advertiserObject.name,
                company_name: advertiserObject.company_name,
                email: advertiserObject.email,
                campaigns: advertiserObject.campaigns.join(","),
            }
            const response = await axios.post(`${BACKEND_URL}/CreateAdvertiser`, object);
            toast.success('success');
            setDisabled(false)
            setAdvertiserObject({
                name: '',
                company_name: '',
                email: '',
                campaigns: [],
            })
        } catch (error) {
            toast.error('Invalid error');
            setDisabled(false)
        }

    }
    return (
        <div className="advertisers-section">
            <div className="block-text center">
                {/* <h6 className="sub-heading"><span>Get in Touch!</span></h6> */}
                <h3 className="text-5xl text-black mb-4 font-bold">Advertisers</h3>
            </div>
            <div className="touch__main flex-wrap">
                {/* <div className="info">
                    <h5 className='text-black text-2xl font-bold'>Welcome to AGS Leads - Your Gateway to Targeted Marketing Success!
                    </h5>
                    <p className='text-black pb-4 text-lg'>
                        At AGS Leads, we understand the critical importance of connecting businesses with the right audience. Our dedicated team and cutting-edge technology ensure that your advertising efforts reach the right people at the right time. Partner with us and unlock a world of possibilities for your brand.
                    </p>
                    <ul className="list">
                        <li>
                            <p className='text-black text-base'><strong>Precision Targeting:</strong> Leverage our advanced targeting capabilities to reach your desired audience with pinpoint accuracy. Whether it’s demographics, interests, or behavior, we help you connect with those who matter most.</p>
                        </li>
                        <li>
                            <p className='text-black text-base'><strong>Quality Leads:</strong> Our commitment to quality ensures that you receive leads that are not only relevant but also have a higher likelihood of conversion. Say goodbye to wasted resources on uninterested prospects.</p>
                        </li>
                        <li>
                            <p className='text-black text-base'>
                                <strong>Scalable Solutions:</strong> Whether you’re a small business or an enterprise, our scalable advertising solutions cater to your specific needs. We grow with you, adapting our strategies to match the evolving dynamics of your business.
                            </p>
                        </li>
                    </ul>
                </div> */}
                <form onSubmit={onSubmitFn} className="form-box">
                    <div className="form-box-child">
                        <div className="form-input-parent">
                            <label className='text-black'>Your name <span className='text-red-500'>*</span></label>
                            <input required type="text"
                                onChange={(e) => advertiserObjectFn('name', e.target.value)}
                                value={advertiserObject.name}
                                className="form-control"
                            />
                        </div>
                    </div>
                    <div className="form-box-child">
                        <div className="form-input-parent">
                            <label className='text-black'>Company name <span className='text-red-500'>*</span></label>
                            <input required type="text" className="form-control"
                                onChange={(e) => advertiserObjectFn('company_name', e.target.value)}
                                value={advertiserObject.company_name}
                            />
                        </div>
                    </div>
                    <div className="form-box-child">
                        <div className="form-input-parent">
                            <label className='text-black'>Email/Skype ID <span className='text-red-500'>*</span></label>
                            <input required type="text" className="form-control"
                                onChange={(e) => advertiserObjectFn('email', e.target.value)}
                                value={advertiserObject.email}
                            />
                        </div>
                    </div>
                    <div className="form-box-child">
                        <div className="form-input-parent">
                            <label className='text-black'>Campaigns <span className='text-red-500'>*</span></label>
                            {/* <input required type="text" className="form-control" onChange={(e) => advertiserObjectFn('campaigns', e.target.value)}
                                value={advertiserObject.campaigns}
                            /> */}
                            <Select
                                mode="tags"
                                className='form-control'
                                style={{ width: '100%' }}
                                value={advertiserObject.campaigns}
                                // placeholder="Tags Mode"
                                onChange={handleChange}
                                options={options}
                            />
                        </div>
                    </div>
                    <div className="row mb-0">
                        <div className="form-input-parent">
                            <button disabled={disabled} className="action-btn font-semibold"><span>Submit</span></button>
                        </div>
                    </div>
                </form>
            </div>
        </div>

    );
};

export default AdvertisersSection;
