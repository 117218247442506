import React from 'react';
import './style.css'
import { NavLink } from 'react-router-dom';
const PageTitle = ({ title }) => {
    return (
        <section className="page-title">
            <div className="shape"></div>
            <div className="shape right s3"></div>
            <div className="page-title-container">
                <div className="page-title-row">
                    <div className="page-title-col">
                        <div className="page-title__body">
                            <div className="page-title__main">
                                <h4 className="title text-5xl text-black mb-4 font-bold" >{title}</h4>
                                <ul className="breacrumb">
                                    <li><NavLink to="/">Home</NavLink></li>
                                    <li><p>{title}</p></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default PageTitle;