import React from 'react';
import './style.css'
import { LOGO_IMG } from '../../utils/imagepath';
import { NavLink } from 'react-router-dom';
const Footer = () => {
    return (
        <footer className="footer">
            <div className="shape"></div>
            <div className="footer-section">
                <div className="row">
                    <div className="col-md-12">
                        <div className="footer__main">
                            {/* <div className="block-text center">
                                <h3 className="heading text-black text-5xl font-bold">Get Newsletter</h3>
                                <p className='text-black'>Get updated with news, tips & tricks</p>
                            </div>
                            <form className="form">
                                <div className="form-group">
                                    <input type="email" className="form-control" placeholder="Your email" />
                                </div>
                                <button className="action-btn"><span className='text-black'>Subscribe</span></button>
                            </form> */}
                            <div
                                className={`flex py-8 px-4 flex-wrap items-center `}
                            >
                                <div
                                    data-aos={"flip-right"}
                                    data-aos-easing="ease-out-cubic"
                                    data-aos-duration="1000"
                                    className={` md:pr-5 call-to-action`}
                                >
                                    <h2 className="text-3xl font-bold mb-4">If you are publisher</h2>
                                    <NavLink to={'/publishers'} className="action-btn font-semibold"><span>Click here</span></NavLink>
                                </div>
                                <div
                                    data-aos={"flip-right"}
                                    data-aos-easing="ease-out-cubic"
                                    data-aos-duration="1000"
                                    className={` md:pl-5 call-to-action`}
                                >
                                    <h2 className="text-3xl font-bold mb-4">If you are advertiser</h2>
                                    <NavLink to={'/advertisers'} className="action-btn font-semibold"><span>Click here</span></NavLink>
                                </div>
                            </div>
                        </div>
                        <div className="footer__bottom">
                            <NavLink className="logo" to="/">
                                <img src={LOGO_IMG} className='w-36 ' alt="AGSLEADS" />
                            </NavLink>
                            <div className="center mb-30 flex items-center flex-col">
                                <ul className="list">
                                    <li><NavLink className='text-sm	text-black font-semibold' to="/">Home</NavLink></li>
                                    <li><NavLink className='text-sm	text-black font-semibold' to="/about-us">About Us</NavLink></li>
                                    <li><NavLink className='text-sm	text-black font-semibold' to="/service">Services</NavLink></li>
                                    <li><NavLink className='text-sm	text-black font-semibold' to="/contact-us">Contact</NavLink></li>
                                </ul>
                                <p className='copy-right text-black'>Copyright ©️ <strong>AGS Leads LLC 2024</strong>, All rights reserved.</p>
                            </div>
                            <ul className="list-social">
                                <li><a target='_blank' href='https://facebook.com/agsleadsllc/'><svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2.57969 9.03953C2.51969 9.03953 1.19969 9.03953 0.599688 9.03953C0.279688 9.03953 0.179688 8.91953 0.179688 8.61953C0.179688 7.81953 0.179688 6.99953 0.179688 6.19953C0.179688 5.87953 0.299688 5.77953 0.599688 5.77953H2.57969C2.57969 5.71953 2.57969 4.55953 2.57969 4.01953C2.57969 3.21953 2.71969 2.45953 3.11969 1.75953C3.53969 1.03953 4.13969 0.559531 4.89969 0.279531C5.39969 0.0995311 5.89969 0.0195312 6.43969 0.0195312H8.39969C8.67969 0.0195312 8.79969 0.139531 8.79969 0.419531V2.69953C8.79969 2.97953 8.67969 3.09953 8.39969 3.09953C7.85969 3.09953 7.31969 3.09953 6.77969 3.11953C6.23969 3.11953 5.95969 3.37953 5.95969 3.93953C5.93969 4.53953 5.95969 5.11953 5.95969 5.73953H8.27969C8.59969 5.73953 8.71969 5.85953 8.71969 6.17953V8.59953C8.71969 8.91953 8.61969 9.01953 8.27969 9.01953C7.55969 9.01953 6.01969 9.01953 5.95969 9.01953V15.5395C5.95969 15.8795 5.85969 15.9995 5.49969 15.9995C4.65969 15.9995 3.83969 15.9995 2.99969 15.9995C2.69969 15.9995 2.57969 15.8795 2.57969 15.5795C2.57969 13.4795 2.57969 9.09953 2.57969 9.03953Z" fill="white"></path></svg></a></li>
                                <li><a target='_blank' href='https://facebook.com/agsleadsllc/'><svg width="15" height="12" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14.5 1.42062C13.9794 1.66154 13.4246 1.82123 12.8462 1.89877C13.4412 1.524 13.8954 0.935077 14.1089 0.225231C13.5541 0.574154 12.9416 0.820615 12.2889 0.958154C11.7621 0.366462 11.0114 0 10.1924 0C8.60337 0 7.32412 1.36062 7.32412 3.02862C7.32412 3.26862 7.34338 3.49938 7.39062 3.71908C5.0045 3.59631 2.89313 2.38985 1.47475 0.552C1.22712 1.00523 1.08188 1.524 1.08188 2.08246C1.08188 3.13108 1.59375 4.06062 2.35675 4.59877C1.89562 4.58954 1.44325 4.44831 1.06 4.22585C1.06 4.23508 1.06 4.24708 1.06 4.25908C1.06 5.73046 2.05487 6.95262 3.3595 7.23415C3.12587 7.30154 2.87125 7.33385 2.607 7.33385C2.42325 7.33385 2.23775 7.32277 2.06362 7.28215C2.4355 8.48123 3.49075 9.36277 4.7455 9.39138C3.769 10.1972 2.52912 10.6828 1.18688 10.6828C0.9515 10.6828 0.72575 10.6717 0.5 10.6412C1.77137 11.5062 3.27813 12 4.903 12C10.1845 12 13.072 7.38462 13.072 3.384C13.072 3.25015 13.0676 3.12092 13.0615 2.99262C13.6311 2.56615 14.1097 2.03354 14.5 1.42062Z" fill="white"></path></svg></a></li>
                                <li><a target='_blank' href="https://www.linkedin.com/company/77124204/admin/feed/posts/"><svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14.0006 14V8.87249C14.0006 6.35249 13.4581 4.42749 10.5181 4.42749C9.10062 4.42749 8.15563 5.19749 7.77063 5.93249H7.73563V4.65499H4.95312V14H7.85813V9.36249C7.85813 8.13749 8.08563 6.96499 9.59063 6.96499C11.0781 6.96499 11.0956 8.34749 11.0956 9.43249V13.9825H14.0006V14Z" fill="white"></path><path d="M0.226562 4.65479H3.13156V13.9998H0.226562V4.65479Z" fill="white"></path><path d="M1.68 0C0.7525 0 0 0.7525 0 1.68C0 2.6075 0.7525 3.3775 1.68 3.3775C2.6075 3.3775 3.36 2.6075 3.36 1.68C3.36 0.7525 2.6075 0 1.68 0Z" fill="white"></path></svg></a></li>
                                <li><a><svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.2347 1.9639C17.1458 1.22216 16.4468 0.510897 15.7154 0.415609C11.2555 -0.138536 6.7457 -0.138536 2.28731 0.415609C1.55533 0.51069 0.856308 1.22216 0.76739 1.9639C0.452537 4.68236 0.452537 7.31818 0.76739 10.036C0.856308 10.7778 1.55533 11.4897 2.28731 11.5843C6.7457 12.1384 11.2557 12.1384 15.7154 11.5843C16.4468 11.4898 17.1458 10.7778 17.2347 10.036C17.5496 7.31842 17.5496 4.68236 17.2347 1.9639ZM7.58931 8.82375V3.17703L11.8243 6.00049L7.58931 8.82375Z" fill="white"></path></svg></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <a id="scroll-top" href="/home-v3"><span className="icon-arrow-top"></span></a>
        </footer>
    );
}

export default Footer;
