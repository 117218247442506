// App.js
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Layout from '../components/layout';
import AboutUs from '../screens/aboutus/aboutus';
import Advertisers from '../screens/advertisers/advertisers';
import BlogScreen from '../screens/blog/blog';
import ContactUs from '../screens/contactus/contactus';
import Home from '../screens/home/home';
import Publishers from '../screens/publishers/publishers';
import Reviews from '../screens/reviews/reviews';
import WhyUs from '../screens/whyus/whyus';
import ServicesPage from '../screens/service/service';


const RouterApp = () => {

    return (
        <BrowserRouter>
            <Layout>
                <div >
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/service" element={<ServicesPage />} />
                        <Route path="/blog" element={<BlogScreen />} />
                        <Route path="/contact-us" element={<ContactUs />} />
                        <Route path="/about-us" element={<AboutUs />} />
                        <Route path="/publishers" element={<Publishers />} />
                        <Route path="/advertisers" element={<Advertisers />} />
                        <Route path="/reviews" element={<Reviews />} />
                        <Route path="/why-us" element={<WhyUs />} />
                    </Routes>
                </div>
            </Layout>
        </BrowserRouter>

    );
};

export default RouterApp;
