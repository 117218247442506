import React from 'react';
import { HEALTH_SECTOR, LEGAL_COMMUNITIES } from '../../../utils/imagepath';
import './specailization.css'
import ImageLoader from '../../loader/imageloader';
const BlogBox = ({ imageUrl, date, title, desc, isLeft }) => {
    return (
        <div className="box-size" data-aos={isLeft ? "flip-right" : 'flip-left'}
            data-aos-easing="ease-out-cubic"
            data-aos-duration="1000">
            <div className="blog-box">
                <div className="image">
                    <a>
                        <ImageLoader src={imageUrl} alt="AGSLEADS" />
                    </a>
                </div>
                <div className="content center">
                    {/* <div className="meta">
                        <p>
                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M2.9375 0.75V2.0625M9.0625 0.75V2.0625M0.75 9.9375V3.375C0.75 3.0269 0.888281 2.69306 1.13442 2.44692C1.38056 2.20078 1.7144 2.0625 2.0625 2.0625H9.9375C10.2856 2.0625 10.6194 2.20078 10.8656 2.44692C11.1117 2.69306 11.25 3.0269 11.25 3.375V9.9375M0.75 9.9375C0.75 10.2856 0.888281 10.6194 1.13442 10.8656C1.38056 11.1117 1.7144 11.25 2.0625 11.25H9.9375C10.2856 11.25 10.6194 11.1117 10.8656 10.8656C11.1117 10.6194 11.25 10.2856 11.25 9.9375M0.75 9.9375V5.5625C0.75 5.2144 0.888281 4.88056 1.13442 4.63442C1.38056 4.38828 1.7144 4.25 2.0625 4.25H9.9375C10.2856 4.25 10.6194 4.38828 10.8656 4.63442C11.1117 4.88056 11.25 5.2144 11.25 5.5625V9.9375" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                            </svg>
                            {date}
                        </p>
                    </div> */}
                    <h3 className="h5 title font-semibold text-black" href="/blog-details">{title}</h3>
                    <p className='text-zinc-400	'>{desc}</p>
                </div>
            </div>
        </div>
    );
};

const SpecializationSection = () => {
    return (
        <div className="specialization-section">
            <div className="row">
                <div className="col-12">
                    <div className="block-text center">
                        <h6 className="sub-heading"><span>Our Specialization</span></h6>

                        <h3 className="heading text-black text-5xl font-bold">We Are Specialized</h3>
                    </div>
                </div>
                <div className='flex flex-wrap'>
                    <BlogBox
                        imageUrl={HEALTH_SECTOR}
                        date="February 18, 2022"
                        title="Health Sector"
                        isLeft={true}
                        desc="At AgsLeads, we understand the critical importance of seamless communication in the healthcare industry."
                    />
                    <BlogBox
                        imageUrl={LEGAL_COMMUNITIES}
                        date="February 18, 2022"
                        title="Legal Communities"
                        desc="AgsLeads offers specialized call center services tailored to meet the unique needs of legal companies."
                    />
                </div>
            </div>
        </div>
    );
};

export default SpecializationSection;
