import React from 'react';
import "./app.css"
import { LINE_IMG } from '../../../utils/imagepath';
const RoadmapBox = ({ time, title, text, isRight }) => {
    return (
        <div className={`roadmap-box ${isRight ? 'right' : ''}`}>
            <div className="time">{time}</div>
            <div className="content">
                <h5 className="title text-black">{title}</h5>
                <p className="text text-black">{text}</p>
            </div>
        </div>
    );
};

const Roadmap = () => {
    return (
        <div className='road-map-section'>
            <img className='img-line' src={LINE_IMG} />
            <div className="shape"></div>
            <div className="block-text center">
                <h6 className="sub-heading"><span>Leading Industry</span></h6>
                <h3 className="text-5xl text-black mb-4 font-bold	">Tailored Solutions for Every Business
                </h3>
                <p className='text-black'>At AgsLeads, we recognize that every business is unique. Our team of experienced professionals works closely with you to understand your specific requirements and create customized call center solutions that align with your goals.</p>
            </div>
            <div className="roadmap__main aos-init aos-animate" data-aos="fade-up" data-aos-duration="2000">
                <RoadmapBox
                    time="February 01, 2022"
                    title="Expert Team"
                    text="Our dedicated team of experts brings years of industry experience and unparalleled expertise to deliver exceptional results, ensuring your project is in the hands of capable professionals who are committed to excellence every step of the way."
                />
                <RoadmapBox
                    time="February 01, 2022"
                    title="Data Security and Compliance"
                    text="With stringent security protocols and rigorous compliance measures, we safeguard your valuable data, providing peace of mind and ensuring confidentiality, integrity, and compliance with industry standards and regulations."
                    isRight
                />
                <RoadmapBox
                    time="February 01, 2022"
                    title="Scalable and Flexible"
                    text="Our solutions are designed to scale effortlessly with your evolving needs, offering flexibility and adaptability to accommodate growth and changes in your business environment, empowering you to stay agile and responsive in a dynamic marketplace."
                />
                <RoadmapBox
                    time="February 01, 2022"
                    title="Friendly 24/7 customer service"
                    text="Our friendly and knowledgeable customer service team is available round-the-clock to provide prompt assistance, resolving queries, and addressing concerns with warmth and professionalism, ensuring an exceptional customer experience that exceeds expectations."
                    isRight
                />
                <RoadmapBox
                    time="February 01, 2022"
                    title="Flexible scheduling"
                    text="Tailored to your convenience, our flexible scheduling options allow you to customize your workflow and optimize productivity, empowering you to manage your time efficiently and maximize resources while maintaining the flexibility to adapt to unforeseen circumstances seamlessly."
                />
                <div className="icon"></div>
                <div className="icon bottom"></div>
            </div>
        </div>
    );
};

export default Roadmap;
