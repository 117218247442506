// BlogBox.js
import React from 'react';
import { BLOG_1, BLOG_2, BLOG_3 } from "../../../utils/imagepath"
import './styles.css'
import ImageLoader from '../../loader/imageloader';

const BlogBox = ({ imageUrl, date, title, link, desc, showDesc, dataaos }) => {
    return (
        <div className="blog-box-md-4"
        data-aos={dataaos}
         data-aos-easing="ease-out-cubic"
        data-aos-duration="1000"
        >
            <div className="blog-box">
                <div className="image">
                    <a >
                        <ImageLoader src={imageUrl} alt="Blog" />
                    </a>
                </div>
                <div className="content center">
                    <div className="meta ">
                        <p>
                            {/* <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M2.9375 0.75V2.0625M9.0625 0.75V2.0625M0.75 9.9375V3.375C0.75 3.0269 0.888281 2.69306 1.13442 2.44692C1.38056 2.20078 1.7144 2.0625 2.0625 2.0625H9.9375C10.2856 2.0625 10.6194 2.20078 10.8656 2.44692C11.1117 2.69306 11.25 3.0269 11.25 3.375V9.9375M0.75 9.9375C0.75 10.2856 0.888281 10.6194 1.13442 10.8656C1.38056 11.1117 1.7144 11.25 2.0625 11.25H9.9375C10.2856 11.25 10.6194 11.1117 10.8656 10.8656C11.1117 10.6194 11.25 10.2856 11.25 9.9375M0.75 9.9375V5.5625C0.75 5.2144 0.888281 4.88056 1.13442 4.63442C1.38056 4.38828 1.7144 4.25 2.0625 4.25H9.9375C10.2856 4.25 10.6194 4.38828 10.8656 4.63442C11.1117 4.88056 11.25 5.2144 11.25 5.5625V9.9375" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                            </svg> */}
                            {date}
                        </p>
                    </div>
                    <a className="h5 title text-lg font-semibold" >{title}</a>
                    {showDesc ? <p className='text-zinc-400 mt-2'>{desc}</p> : null}
                </div>
            </div>
        </div>
    );
}
const BlogSection = ({ showDesc }) => {
    return (
        <section className={`blog-section s2 ${!showDesc ? 'padding-blog-section' : ''}`}>
            {/* Section content */}
            <div className="blog-section-container">
                <div className="blog-section-row">
                    <div className="blog-section-col-12">
                        <div className="block-text center">
                            <h6 className="sub-heading"><span>Our recent posts</span></h6>
                            <h3 className="text-5xl text-black mb-4 font-bold pd">News and blogs</h3>
                        </div>
                    </div>


                    {/* Blog posts */}
                    <div className='flex blog-box flex-wrap' >
                        <BlogBox
                            imageUrl={BLOG_1}
                            date="February 18, 2022"
                            title="Embracing Change: The Evolution of Call Center Services in the Digital Era"
                            link="/blog-details"
                            showDesc={showDesc}
                            dataaos="flip-right"
                            desc='The landscape of call center services is undergoing a significant transformation with the advent of digital technologies. In this blog...'
                        />
                        <BlogBox
                            imageUrl={BLOG_2}
                            date="February 18, 2022"
                            title="The Art of Multilingual Customer Support: Breaking Barriers and Building Connections"
                            link="/blog-details"
                            showDesc={showDesc}
                            dataaos="zoom-in"
                            desc='In a globalized world, businesses must be equipped to communicate with a diverse customer base. This blog post delves into...'
                        />
                        <BlogBox
                            imageUrl={BLOG_3}
                            date="February 18, 2022"
                            title="Embracing Change: The Evolution of Call Center Services in the Digital Era"
                            link="/blog-details"
                            showDesc={showDesc}
                            dataaos="flip-left"
                            desc='The landscape of call center services is undergoing a significant transformation with the advent of digital technologies. In this blog...'
                        />
                    </div>
                </div>
            </div>
        </section>
    );
}

export default BlogSection;

