import React from "react";
import "./style.css";
import ImageLoader from "../loader/imageloader";
import {
  About_us,
  Customer_Service,
  Lead_Generation,
  Pay_Per_Call,
} from "../../utils/imagepath";
const ServiceContnet = ({ title, desc, alt, image, right, animation }) => {
  return (
    <div
      className={`flex py-8 px-4 flex-wrap items-center ${
        right ? "flex-row-reverse" : ""
      }`}
    >
      <div
        data-aos={!animation && "flip-right"}
        data-aos-easing="ease-out-cubic"
        data-aos-duration="1000"
        className={` w-full md:w-1/2 ${
          right ? "md:pl-5" : "md:pr-5"
        } text-left`}
      >
        <h2 className="text-3xl font-bold mb-4">{title}</h2>
        <p className="text-gray-700 text-lg	">{desc}</p>
      </div>
      <div
        data-aos={!animation && "flip-left"}
        data-aos-easing="ease-out-cubic"
        data-aos-duration="1000"
        className="w-full md:w-1/2 mb-8 mt-8 md:mt-0 md:mb-0"
      >
        <ImageLoader
          src={image}
          alt={alt}
          className="object-cover rounded-lg h-full w-full"
        />
      </div>
    </div>
  );
};
const Services = ({ title, para }) => {
  return (
    <div className="why-us-page">
      {title && (
        <div className="block-text center">
          {/* <h6 className="sub-heading">
            <span></span>
          </h6> */}
          <h3 className="text-5xl text-black mb-4 font-bold">{title}</h3>
          <p className="text-black">{para}</p>
        </div>
      )}
      <div className={`flex ${!title && "py-8"} px-4 flex-wrap items-center`}>
        <ServiceContnet
          title={"Pey Per Call"}
          animation={false}
          desc={`Our Pay Per Call experts are eagerly waiting to lauch, monitor and
            otimize your advertising and marketing campaigns. Our PPC strategies
            are designed for achieving high conversions, enhancing your online
            presence.`}
          image={Pay_Per_Call}
          alt={"Pey Per Call"}
        />
        <ServiceContnet
          right={true}
          title={"Lead Generation"}
          animation={false}
          desc={`Having recognized lead generation as the bedrock of any business's
            success, AGS Leads is your first choice to enhance sales and rule in
            the industry.`}
          image={Lead_Generation}
          alt={"Lead Generation"}
        />
        <ServiceContnet
          title={"Customer Support Services"}
          animation={false}
          desc={`At AGS Leads, we offer chat support, voice calls, inbound calls, and
            email support. Our expert team ensures prompt, personalized
            assistance, fostering satisfaction and loyalty. Additionally, we
            provide tailored business solutions for optimized operations. Choose
            us for excellence in customer support and business success.`}
          image={Customer_Service}
          alt={"Pey Per Call"}
        />
      </div>
    </div>
  );
};

export default Services;
