import axios from 'axios';
import React, { useState } from 'react';
import { BACKEND_URL } from '../../../utils/constant';
import './style.css';
import { toast } from 'react-toastify';
import { Select } from 'antd';
const options = [];
const PublishersSection = () => {
    const [publisherObject, setPublisherObject] = useState({
        name: '',
        company_name: '',
        email: '',
        campaigns: [],
    })
    const [disabled, setDisabled] = useState(false)
    const publisherObjectFn = (key, value) => {
        console.log({ [key]: value });
        setPublisherObject({ ...publisherObject, [key]: value })
    }
    const onSubmitFn = async (event) => {
        event.preventDefault();
        setDisabled(true)
        try {
            let object = {
                name: publisherObject.name,
                company_name: publisherObject.company_name,
                email: publisherObject.email,
                campaigns: publisherObject.campaigns.join(","),
            }
            const response = await axios.post(`${BACKEND_URL}/CreatePublisher`, object);
            toast.success('success');
            setDisabled(false)
            setPublisherObject({
                name: '',
                company_name: '',
                email: '',
                campaigns: [],
            })
        } catch (error) {
            toast.error('Invalid error');
            setDisabled(false)
        }

    }
    const handleChange = (value) => {
        console.log(`selected ${value}`);
        publisherObjectFn('campaigns', value)
    };

    return (
        <div className="publishers-section">
            <div className="block-text center">
                {/* <h6 className="sub-heading"><span>Get in Touch!</span></h6> */}
                <h3 className="text-5xl text-black mb-4 font-bold">Publishers</h3>
            </div>
            <div className="touch__main flex-wrap">
                {/* <div className="info">
                    <h5 className='text-black text-2xl font-bold'>Unlock Your Earning Potential with AGS Leads - Join Our Publisher Network!</h5>
                    <p className='text-black pb-4 text-lg'>
                        Are you a website owner, content creator, or social media influencer looking to monetize your audience? AGS Leads offers a lucrative opportunity for publishers to maximize their revenue while delivering valuable content to their audience.
                    </p>
                    <ul className="list">
                        <li>
                            <p className='text-black text-base'><strong>Diverse Campaigns:</strong> Access a wide range of campaigns from top advertisers across various industries. Choose the ones that align with your audience, ensuring a seamless integration of ads into your content.</p>
                        </li>
                        <li>
                            <p className='text-black text-base'><strong>Competitive Payouts:</strong> Earn competitive commissions for every lead or sale generated through your platform. Our transparent payout structure ensures that your efforts are rewarded fairly.</p>
                        </li>
                        <li>
                            <p className='text-black text-base'>
                                <strong>User-Friendly Platform:</strong> Our intuitive platform makes it easy for publishers to navigate and integrate advertising campaigns seamlessly. Focus on creating great content while we handle the technicalities.
                            </p>
                        </li>
                    </ul>
                </div> */}
                <form onSubmit={onSubmitFn} className="form-box">
                    <div className="form-box-child">
                        <div className="form-input-parent">
                            <label className='text-black'>Your name <span className='text-red-500'>*</span></label>
                            <input type="text"
                                required
                                onChange={(e) => publisherObjectFn('name', e.target.value)}
                                value={publisherObject.name} className="form-control" />
                        </div>
                    </div>
                    <div className="form-box-child">
                        <div className="form-input-parent">
                            <label className='text-black'>Company name <span className='text-red-500'>*</span></label>
                            <input type="text" className="form-control"
                                required
                                onChange={(e) => publisherObjectFn('company_name', e.target.value)}
                                value={publisherObject.company_name} />
                        </div>
                    </div>
                    <div className="form-box-child">
                        <div className="form-input-parent">
                            <label className='text-black'>Email/Skype ID <span className='text-red-500'>*</span></label>
                            <input type="text" className="form-control"
                                required
                                onChange={(e) => publisherObjectFn('email', e.target.value)}
                                value={publisherObject.email}
                            />
                        </div>
                    </div>
                    <div className="form-box-child">
                        <div className="form-input-parent">
                            <label className='text-black'>Campaigns <span className='text-red-500'>*</span></label>
                            <Select
                                mode="tags"
                                className='form-control'
                                style={{ width: '100%' }}
                                value={publisherObject.campaigns}
                                // placeholder="Tags Mode"
                                onChange={handleChange}
                                options={options}
                            />

                            {/* <input required type="text" className="form-control" onChange={(e) => publisherObjectFn('campaigns', e.target.value)}
                                value={publisherObject.campaigns}
                            /> */}
                        </div>
                    </div>
                    <div className="row mb-0">
                        <div className="form-input-parent">
                            <button disabled={disabled} className="action-btn font-semibold" type='submit'><span>Submit</span></button>
                        </div>
                    </div>
                </form>
            </div>
        </div>

    );
};

export default PublishersSection;
