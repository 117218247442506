import Aos from 'aos';
import { useEffect } from 'react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import './App.css';
// import { initParticlesEngine } from '@tsparticles/react';
// import { loadSlim } from '@tsparticles/slim';
import 'aos/dist/aos.css'; // You can also use <link> for styles
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import RouterApp from './navigation';

function App() {
  useEffect(() => {
    Aos.init();
  }, [])
  // useLayoutEffect(() => {
  //   // initParticlesEngine(async (engine) => {
  //   //   //   // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
  //   //   //   // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
  //   //   //   // starting from v2 you can add only the features you need reducing the bundle size
  //   //   //   //await loadAll(engine);
  //   //   //   //await loadFull(engine);
  //   //   await loadSlim(engine);
  //   //   Aos.init();
  //   //   //await loadBasic(engine);
  //   // }).then(() => {
  //   //   // setInit(true);
  //   // });
  // }, []);

  return (
    <div className="App  bg-[#DDD0C8]">
      {/* <Suspense fallback={() => <></>}> */}
      <RouterApp />
      <ToastContainer />
      {/* </Suspense> */}
    </div>
  );
}

export default App;
