import React from 'react'
import PageTitle from '../../components/pagetitle/pagetitle'
import WhyUsPage from '../../components/whyus/whyuscontent/whyuspage'
import AboutUsPage from '../../components/aboutus/aboutus'

export default function AboutUs() {
    React.useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <React.Fragment>
            <PageTitle title={"About us"} />
            <AboutUsPage />
        </React.Fragment>
    )
}
