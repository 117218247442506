import React from 'react'
import Header from '../header/header'
import Footer from '../footer/footer'
import ParticlesComponent from '../particales/particales'

export default function Layout(props) {
    return (
        <React.Fragment>
            {/* <ParticlesComponent /> */}
            <Header />
            {props.children}
            <Footer />
        </React.Fragment>
    )
}
